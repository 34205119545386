<template>
  <!-- Example usage:
    <autocompleteMultiLuRole
      v-model="selectedUser.UserRole" (the collection we are updating)
      selfKey="UserID" (how the selectedUser is referenced in the join item)
      :selfID="selectedUser.ID" (to create new entries we need the selected users ID)
      relatedItemKey="RoleID" (this is how the lookup is referenced in the join)
      joinItemName="UserRole" (this is the name of the join (for the js class))
    ></autocompleteMultiLuRole>
  -->
  <v-autocomplete
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="ID"
    :clearable="clearable"
    :search-input.sync="searchInput"
    @change="searchInput=''"
    :rules="rules"
    :loading="loading"
    chips
    deletable-chips
    multiple
    dense
  ></v-autocomplete>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  import UserRole from '@classes/UserRole'
  export default {
    name: 'autocomplete-multi-join-LuRole',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        default: 'Lu Role',
        },
      value: Array,
      clearable: {
        type: Boolean,
        default: false,
      },
      rules: Array,
      joinItemName: {
        type: String,
        required: true,
        //default: 'UserRole',
      },
      relatedItemKey: {
        type: String,
        required: true,
        //default: 'LuRoleID',
      },
      selfKey: {
        type: String,
        required: true,
      },
      selfID: {
        type: Number,
        required: true,
      }
    },
    data(){
      return {
        //templateFIX: if there is more than one pick one to display

        itemText: 'Description',
        searchInput: '',
      }
    },
    watch: {
      value(nval, oval) {
        if (nval != oval) {
          this.searchInput = ''
        }
      },
    },
    computed: {
      ...get('luRole',['luRole', 'loading']),
      availableItems() {
          //if you have a property (like an IsActive boolean) you can change the true to filer i.e. i.IsActive
          var list = this.luRole.filter((i) => true )
          // if existing record make sure the previous selection is available even if no longer active
          if (this.value > 0) {
            var selectedItem = this.luRole.find((i) => i.ID == this.value)
            if (!list.find((i) => i.ID == this.value) && selectedItem) {
              list.push(selectedItem)
            }
          }
          // sort by column
          list.sort((a, b) => {
            //set the property to sort on
            let textField = this.itemText
            let sort = a[textField] > b[textField] ? 1 : -1
            return sort
          })
          return list
      },
      selected: {
        get() {
          if (this.value && this.value.length > 0) {
            return [
              ...new Set(
                this.value.map((item) => item[this.relatedItemKey])
              ),
            ]
          } else {
            return []
          }
        },
        set(newValue) {
          if (this.value) {
            this.value.forEach(
            (relation, index, object) => {
              if (!newValue.includes(relation[this.relatedItemKey])) {
                //need to remove item
                object.splice(index, 1)
              }
            }
            )
            newValue.forEach((itemID) => {
              if (
                !this.value.find(
                  (i) => i[this.relatedItemKey] == itemID
                )
              ) {
                // need to add item
                let item
                if (this.joinItemName == 'UserRole') {
                  item = new UserRole()
                }
                else
                {item = {}}
                item[this.relatedItemKey]= itemID
                item[this.selfKey]= this.selfID
                this.value.push(item)
              }
            })
          }
        }
      }
    },
    created(){
      this.loadLuRole()
    },
    methods: {
      loadLuRole: call('luRole/loadLuRole'),
    },
  }
</script>
<style>

</style>