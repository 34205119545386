import Document from './Document'
import LuLicenseType from './LuLicenseType'
export default class LuTemplateDocuments {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      //DocumentID is required
      DocumentID: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.DocumentID = obj.DocumentID
    this.Document = new Document(obj.Document || {})
    this.LuLicenseType = []
    if (obj.LuLicenseType) {
      obj.LuLicenseType.forEach((item) => {
        this.LuLicenseType.push(new LuLicenseType(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Document = null
    obj.LuLicenseType = []
    if (this.LuLicenseType) {
      this.LuLicenseType.forEach((item) => {
        obj.LuLicenseType.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Document = null
    obj.LuLicenseType = null
    return obj
  }
}
