<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="primary headline text-left white--text">License Type</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" fab @click="addLuLicenseType" depressed small>
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add License Type</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog persistent v-model="dialog" @keydown.esc="dialog = false" width="850">
            <v-form ref="luLicenseTypeForm" @submit.prevent lazy-validation v-model="valid">
              <v-card class="elevation-3">
                <v-card-title class="primary headline text-left white--text">{{modalText}}</v-card-title>
                <v-card-text class="pa-4">
                  <v-row>
                    <v-col sm="12">
                      <v-text-field label="Name" v-model="selectedLuLicenseType.Name"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Description"
                        v-model="selectedLuLicenseType.Description"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="4">
                      <v-text-field
                        label="Expire Date Each Year"
                        v-model="selectedLuLicenseType.ExpireDateEachYear"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4" class="mt-2">
                      <v-checkbox
                        label="Multiple Sub Types Available"
                        v-model="selectedLuLicenseType.MultipleSubTypesAvailable"
                      ></v-checkbox>
                    </v-col>
                    <v-col sm="12" md="4" class="mt-2">
                      <v-checkbox label="Single Fee" v-model="selectedLuLicenseType.SingleFee"></v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Insert By I D"
                        v-model="selectedLuLicenseType.InsertByID"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Insert Date mm/dd/yyyy"
                        v-model="selectedLuLicenseType.InsertDate"
                        :rules="[v => !!v || 'required']"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Update By I D"
                        v-model="selectedLuLicenseType.UpdateByID"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Update Date mm/dd/yyyy"
                        v-model="selectedLuLicenseType.UpdateDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="License Template I D"
                        v-model="selectedLuLicenseType.LicenseTemplateID"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                  <!-- //templateTODO: The multiselects will need to have the props updated to the correct values

                  We are not able to determine the nested relations for the template generation
                  you will also need to load the appropriate lookup component-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <v-row no-gutters>
                    <v-col sm="12">
                      <LuTemplateDocuments
                      label="License Template"
                      v-model="selectedLuLicenseType.LicenseTemplateID"
                      ></LuTemplateDocuments>
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedLuLicenseType.License"
                      selfKey="LuLicenseTypeID"
                      :selfID="selectedLuLicenseType.ID"
                      relatedItemKey="RelationID"
                      joinItemName="License"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <v-row no-gutters>
                    <v-col sm="12">
                      <LuDocumentType
                      label="Available Document Types"
                      v-model="selectedLuLicenseType.LicenseDocumentTypes"
                      selfKey="LicenseTypeID"
                      :selfID="selectedLuLicenseType.ID"
                      relatedItemKey="DocumentTypeID"
                      joinItemName="LicenseDocumentTypes"
                      ></LuDocumentType>
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedLuLicenseType.LuLicenseSubType"
                      selfKey="LuLicenseTypeID"
                      :selfID="selectedLuLicenseType.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseSubType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <v-card-actions>
                    <v-btn @click="saveLuLicenseTypeDetails" :disabled="saving" color="primary">Save</v-btn>
                    <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="luLicenseType"
            :search="searchText"
            class="pt-4"
            :loading="loading"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="editLuLicenseType(item)">
                    <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit License Type Information</span>
              </v-tooltip>
            </template>
            <template v-slot:item.IsActive="{ item }">{{ item.IsActive? 'Active': 'Inactive'}}</template>
            <template v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="deleteConfirm(item)">
                    <v-icon class="clickable" color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete License Type</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary headline">Confirm?</v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12">Are you sure you want to delete this luLicenseType?</v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="deleteDialog = !deleteDialog">Cancel</v-btn>
                  <v-btn @click="callDelete" outlined color="primary">Delete</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuLicenseType from '@classes/LuLicenseType'
import LuTemplateDocuments from '@components/select/single/LuTemplateDocuments'
import LuDocumentType from '@components/select/multi-join/LuDocumentType'
//templateTODO: import lookup components you plan to use
export default {
  page: {
    title: 'LuLicenseType',
  },
  components: {
LuTemplateDocuments,
LuDocumentType
  },
  data: () => ({
    valid: true,
    selectedLuLicenseType: new LuLicenseType(),
    searchText: '',
    dialog: false,
    deleteDialog: false,
    modalText: '',
  }),
  created() {
    this.loadLuLicenseType()
  },
  computed: {
    ...get('luLicenseType', ['luLicenseType', 'saving', 'loading']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'I D',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Name',
          align: 'center',
          sortable: true,
          value: 'Name',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Expire Date Each Year',
          align: 'center',
          sortable: true,
          value: 'ExpireDateEachYear',
          visible: true,
        },
        {
          text: 'Multiple Sub Types Available',
          align: 'center',
          sortable: true,
          value: 'MultipleSubTypesAvailable',
          visible: false,
        },
        {
          text: 'Single Fee',
          align: 'center',
          sortable: true,
          value: 'SingleFee',
          visible: false,
        },
        {
          text: 'Insert By I D',
          align: 'center',
          sortable: true,
          value: 'InsertByID',
          visible: false,
        },
        {
          text: 'Insert Date',
          align: 'center',
          sortable: true,
          value: 'InsertDate',
          visible: false,
        },
        {
          text: 'Update By I D',
          align: 'center',
          sortable: true,
          value: 'UpdateByID',
          visible: false,
        },
        {
          text: 'Update Date',
          align: 'center',
          sortable: true,
          value: 'UpdateDate',
          visible: false,
        },
        {
          text: 'License Template I D',
          align: 'center',
          sortable: true,
          value: 'LicenseTemplateID',
          visible: false,
        },
        //{
        //  text: 'Insert By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'InsertBy.Description',
        //  visible: true,
        //},
        //{
        //  text: 'License Template',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'LicenseTemplate.Description',
        //  visible: true,
        //},
        //{
        //  text: 'Update By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'UpdateBy.Description',
        //  visible: true,
        //},
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luLicenseType', [
      'saveLuLicenseType',
      'deleteLuLicenseType',
      'loadLuLicenseType',
      'clearLookupCacheAndReloadLuLicenseType',
    ]),
    async validate() {
      this.$refs.luLicenseTypeForm.validate()
    },
    editLuLicenseType(entry) {
      this.selectedLuLicenseType = new LuLicenseType(entry)
      this.modalText = 'Edit License Type'
      this.dialog = !this.dialog
    },
    addLuLicenseType() {
      this.modalText = 'Add License Type'
      this.selectedLuLicenseType = new LuLicenseType()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedLuLicenseType = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteLuLicenseType(this.selectedLuLicenseType).then(
        (res) => {
          this.clearLookupCacheAndReloadLuLicenseType()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveLuLicenseTypeDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuLicenseType(
            this.selectedLuLicenseType.removeRelated()
          ).then(
            (res) => {
              this.clearLookupCacheAndReloadLuLicenseType()
              this.selectedLuLicenseType = new LuLicenseType()
              this.$refs.luLicenseTypeForm.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuLicenseType = new LuLicenseType()
      this.$refs.luLicenseTypeForm.resetValidation()
    },
  },
}
</script>
<style>
</style>