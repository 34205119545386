<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="primary headline text-left white--text">User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" fab @click="addUser" depressed small>
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add User</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog persistent v-model="dialog" @keydown.esc="dialog = false" width="850">
            <v-form ref="userForm" @submit.prevent lazy-validation v-model="valid">
              <v-card class="elevation-3">
                <v-card-title class="primary headline text-left white--text">{{modalText}}</v-card-title>
                <v-card-text class="pa-4">
                  <v-row>
                    <v-col sm="12" md="6">
                      <v-text-field
                        label="First Name"
                        v-model="selectedUser.FirstName"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        label="Last Name"
                        v-model="selectedUser.LastName"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="6">
                      <v-text-field
                        label="Email"
                        v-model="selectedUser.Email"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" class="mt-1">
                      <LuRole
                        label="User Role"
                        v-model="selectedUser.UserRole"
                        selfKey="UserID"
                        :selfID="selectedUser.ID"
                        relatedItemKey="RoleID"
                        joinItemName="UserRole"
                      ></LuRole>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-checkbox
                        label="Is Active"
                        v-model="selectedUser.IsActive"
                        :rules="[v => v === true || v === false || 'required']"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-checkbox
                        label="Deleted By User"
                        v-model="selectedUser.DeletedByUser"
                        :rules="[v => v === true || v === false || 'required']"
                      ></v-checkbox>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Insert By I D"
                        v-model="selectedUser.InsertByID"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Insert Date mm/dd/yyyy"
                        v-model="selectedUser.InsertDate"
                        :rules="[v => !!v || 'required']"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Update By I D"
                        v-model="selectedUser.UpdateByID"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Update Date mm/dd/yyyy"
                        v-model="selectedUser.UpdateDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- //templateTODO: The multiselects will need to have the props updated to the correct values

                  We are not able to determine the nested relations for the template generation
                  you will also need to load the appropriate lookup component-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.Business"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="Business"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.Business"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="Business"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.Document"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="Document"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.HempLicenseArea"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="HempLicenseArea"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.HempLicenseArea"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="HempLicenseArea"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.HempLicense"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="HempLicense"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.HempLicense"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="HempLicense"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LicenseDocumentTypes"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseDocumentTypes"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LicenseDocumentTypes"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseDocumentTypes"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.License"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="License"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LicenseNotes"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseNotes"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LicenseNotes"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseNotes"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LicensePeriod"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicensePeriod"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LicensePeriod"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicensePeriod"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LicenseStatus"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseStatus"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.License"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="License"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuBusinessType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuBusinessType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuBusinessType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuBusinessType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuDocumentType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuDocumentType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuDocumentType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuDocumentType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuHempOwnership"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuHempOwnership"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuHempOwnership"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuHempOwnership"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuLicenseStatus"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseStatus"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuLicenseStatus"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseStatus"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuLicenseSubType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseSubType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuLicenseSubType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseSubType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuLicenseType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.LuLicenseType"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.PaymentDetail"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="PaymentDetail"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.PaymentDetail"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="PaymentDetail"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.Payment"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="Payment"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.Payment"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="Payment"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.UserBusiness"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="UserBusiness"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.UserBusiness"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="UserBusiness"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedUser.UserBusiness"
                      selfKey="UserID"
                      :selfID="selectedUser.ID"
                      relatedItemKey="RelationID"
                      joinItemName="UserBusiness"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <v-card-actions>
                    <v-btn @click="saveUserDetails" :disabled="saving" color="primary">Save</v-btn>
                    <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="user"
            :search="searchText"
            class="pt-4"
            :loading="loading"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="editUser(item)">
                    <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit User Information</span>
              </v-tooltip>
            </template>
            <template v-slot:item.InsertDate="{ item }">
              {{
              item.InsertDate | dateformat
              }}
            </template>
            <template v-slot:item.IsActive="{ item }">{{ item.IsActive? 'Active': 'Inactive'}}</template>
            <template v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="deleteConfirm(item)">
                    <v-icon class="clickable" color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete User</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary headline">Confirm?</v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12">Are you sure you want to delete this user?</v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="deleteDialog = !deleteDialog">Cancel</v-btn>
                  <v-btn @click="callDelete" outlined color="primary">Delete</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuRole from '@components/select/a-multi-join/LuRole'
import User from '@classes/User'
import UserRole from '@classes/UserRole'
//templateTODO: import lookup components you plan to use
export default {
  page: {
    title: 'User',
  },
  components: {
    LuRole,
  },
  data: () => ({
    valid: true,
    selectedUser: new User(),
    searchText: '',
    dialog: false,
    deleteDialog: false,
    modalText: '',
  }),
  created() {
    this.loadUser()
  },
  computed: {
    ...get('user', ['user', 'saving', 'loading']),
    ...get('userRole', ['userRole', 'saving']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'I D',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Email',
          align: 'center',
          sortable: true,
          value: 'Email',
          visible: true,
        },
        {
          text: 'First Name',
          align: 'center',
          sortable: true,
          value: 'FirstName',
          visible: true,
        },
        {
          text: 'Last Name',
          align: 'center',
          sortable: true,
          value: 'LastName',
          visible: true,
        },
        {
          text: 'Role',
          align: 'center',
          sortable: true,
          value: 'UserRole.Description',
          visible: false,
        },
        {
          text: 'Active?',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: true,
        },
        {
          text: 'Deleted By User',
          align: 'center',
          sortable: true,
          value: 'DeletedByUser',
          visible: false,
        },
        {
          text: 'Insert By I D',
          align: 'center',
          sortable: true,
          value: 'InsertByID',
          visible: false,
        },
        {
          text: 'Insert Date',
          align: 'center',
          sortable: true,
          value: 'InsertDate',
          visible: false,
        },
        {
          text: 'Update By I D',
          align: 'center',
          sortable: true,
          value: 'UpdateByID',
          visible: false,
        },
        {
          text: 'Update Date',
          align: 'center',
          sortable: true,
          value: 'UpdateDate',
          visible: false,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('user', [
      'saveUser',
      'deleteUser',
      'loadUser',
      'clearLookupCacheAndReloadUser',
    ]),
    async validate() {
      this.$refs.userForm.validate()
    },
    editUser(entry) {
      this.selectedUser = new User(entry)
      this.modalText = 'Edit User'
      this.dialog = !this.dialog
    },
    addUser() {
      this.modalText = 'Add User'
      this.selectedUser = new User()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedUser = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteUser(this.selectedUser).then(
        (res) => {
          this.clearLookupCacheAndReloadUser()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveUserDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveUser(this.selectedUser.removeRelated()).then(
            (res) => {
              this.clearLookupCacheAndReloadUser()
              this.selectedUser = new User()
              this.$refs.userForm.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedUser = new User()
      this.$refs.userForm.resetValidation()
    },
  },
}
</script>
<style>
</style>