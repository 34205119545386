<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="primary headline text-left white--text">License SubType</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" fab @click="addLuLicenseSubType" depressed small>
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add LuLicenseSubType</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog persistent v-model="dialog" @keydown.esc="dialog = false" width="850">
            <v-form ref="luLicenseSubTypeForm" @submit.prevent lazy-validation v-model="valid">
              <v-card class="elevation-3">
                <v-card-title class="primary headline text-left white--text">{{modalText}}</v-card-title>
                <v-card-text class="pa-4">
                  <v-row>
                    <!-- <v-col sm="12" md="4">
                      <v-text-field
                        label="License Type ID"
                        v-model="selectedLuLicenseSubType.LicenseTypeID"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col> -->
                    <v-col sm="12" md="4">
                      <v-text-field
                        label="License Code"
                        v-model="selectedLuLicenseSubType.LicenseCode"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4">
                      <v-text-field
                        label="Revenue Code"
                        v-model="selectedLuLicenseSubType.RevenueCode"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="4">
                      <v-text-field
                        label="Description"
                        v-model="selectedLuLicenseSubType.Description"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4">
                      <v-text-field
                        label="License Fee"
                        v-model="selectedLuLicenseSubType.LicenseFee"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4">
                      <v-text-field
                        label="Term In Years"
                        v-model="selectedLuLicenseSubType.TermInYears"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field 
                        label="Insert By I D" 
                        v-model="selectedLuLicenseSubType.InsertByID"
                        type="number"
                       :rules="[v => !!v || 'required']"
                     ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText 
                        label="Insert Date mm/dd/yyyy"
                        v-model="selectedLuLicenseSubType.InsertDate" 
                        :rules="[v => !!v || 'required']"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field 
                        label="Update By I D" 
                        v-model="selectedLuLicenseSubType.UpdateByID"
                        type="number"
                     ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText 
                        label="Update Date mm/dd/yyyy"
                        v-model="selectedLuLicenseSubType.UpdateDate" 
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- //templateTODO: The multiselects will need to have the props updated to the correct values

                  We are not able to determine the nested relations for the template generation
                  you will also need to load the appropriate lookup component-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <v-row no-gutters>
                    <v-col sm="12">
                      <LuLicenseType
                      label="License Type"
                      v-model="selectedLuLicenseSubType.LicenseTypeID"
                        :rules="[v => !!v || 'required']"
                      ></LuLicenseType>
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedLuLicenseSubType.LicenseDocumentTypes"
                      selfKey="LuLicenseSubTypeID"
                      :selfID="selectedLuLicenseSubType.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseDocumentTypes"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedLuLicenseSubType.LicenseSubType"
                      selfKey="LuLicenseSubTypeID"
                      :selfID="selectedLuLicenseSubType.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseSubType"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <v-card-actions>
                    <v-btn
                      @click="saveLuLicenseSubTypeDetails"
                      :disabled="saving"
                      color="primary"
                    >Save</v-btn>
                    <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="luLicenseSubType"
            :search="searchText"
            class="pt-4"
            :loading="loading"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="editLuLicenseSubType(item)">
                    <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit License SubType Information</span>
              </v-tooltip>
            </template>
            <template v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="deleteConfirm(item)">
                    <v-icon class="clickable" color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete License SubType</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary headline">Confirm?</v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12">Are you sure you want to delete this luLicenseSubType?</v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="deleteDialog = !deleteDialog">Cancel</v-btn>
                  <v-btn @click="callDelete" outlined color="primary">Delete</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuLicenseSubType from '@classes/LuLicenseSubType'
import LuLicenseType from '@components/select/single/LuLicenseType'
//templateTODO: import lookup components you plan to use
export default {
  page: {
    title: 'LuLicenseSubType',
  },
  data: () => ({
    valid: true,
    selectedLuLicenseSubType: new LuLicenseSubType(),
    searchText: '',
    dialog: false,
    deleteDialog: false,
    modalText: '',
  }),
  created() {
    this.loadLuLicenseSubType()
  },
  components: {
LuLicenseType
  },
  computed: {
    ...get('luLicenseSubType', ['luLicenseSubType', 'saving', 'loading']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'I D',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'License Type',
          align: 'center',
          sortable: true,
          value: 'LicenseType.Name',
          visible: true,
        },
        {
          text: 'License Code',
          align: 'center',
          sortable: true,
          value: 'LicenseCode',
          visible: true,
        },
        {
          text: 'Revenue Code',
          align: 'center',
          sortable: true,
          value: 'RevenueCode',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'License Fee',
          align: 'center',
          sortable: true,
          value: 'LicenseFee',
          visible: true,
        },
        {
          text: 'Term In Years',
          align: 'center',
          sortable: true,
          value: 'TermInYears',
          visible: true,
        },
        {
          text: 'Insert By I D',
          align: 'center',
          sortable: true,
          value: 'InsertByID',
          visible: false,
        },
        {
          text: 'Insert Date',
          align: 'center',
          sortable: true,
          value: 'InsertDate',
          visible: false,
        },
        {
          text: 'Update By I D',
          align: 'center',
          sortable: true,
          value: 'UpdateByID',
          visible: false,
        },
        {
          text: 'Update Date',
          align: 'center',
          sortable: true,
          value: 'UpdateDate',
          visible: false,
        },
        //{
        //  text: 'Insert By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'InsertBy.Description',
        //  visible: true,
        //},
        //{
        //  text: 'License Type',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'LicenseType.Description',
        //  visible: true,
        //},
        //{
        //  text: 'Update By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'UpdateBy.Description',
        //  visible: true,
        //},
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luLicenseSubType', [
      'saveLuLicenseSubType',
      'deleteLuLicenseSubType',
      'loadLuLicenseSubType',
      'clearLookupCacheAndReloadLuLicenseSubType',
    ]),
    async validate() {
      this.$refs.luLicenseSubTypeForm.validate()
    },
    editLuLicenseSubType(entry) {
      this.selectedLuLicenseSubType = new LuLicenseSubType(entry)
      this.modalText = 'Edit License SubType'
      this.dialog = !this.dialog
    },
    addLuLicenseSubType() {
      this.modalText = 'Add License SubType'
      this.selectedLuLicenseSubType = new LuLicenseSubType()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedLuLicenseSubType = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteLuLicenseSubType(this.selectedLuLicenseSubType).then(
        (res) => {
          this.clearLookupCacheAndReloadLuLicenseSubType()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveLuLicenseSubTypeDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuLicenseSubType(
            this.selectedLuLicenseSubType.removeRelated()
          ).then(
            (res) => {
              this.clearLookupCacheAndReloadLuLicenseSubType()
              this.selectedLuLicenseSubType = new LuLicenseSubType()
              this.$refs.luLicenseSubTypeForm.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuLicenseSubType = new LuLicenseSubType()
      this.$refs.luLicenseSubTypeForm.resetValidation()
    },
  },
}
</script>
<style>
</style>