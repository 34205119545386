<template>
  <v-container>
    <v-tabs color="secondary">
      <v-tab>Users</v-tab>
      <v-tab-item>
        <User></User>
      </v-tab-item>
      <v-tab>Business Type</v-tab>
      <v-tab-item>
        <LuBusinessType></LuBusinessType>
      </v-tab-item>

      <v-tab>Ownership</v-tab>
      <v-tab-item>
        <LuHempOwnership></LuHempOwnership>
      </v-tab-item>
      <v-tab>License Type</v-tab>
      <v-tab-item>
        <LuLicenseType></LuLicenseType>
      </v-tab-item>
      <v-tab>License Sub Type</v-tab>
      <v-tab-item>
        <LuLicenseSubType></LuLicenseSubType>
      </v-tab-item>
      <v-tab>Template Documents</v-tab>
      <v-tab-item>
        <LuTemplateDocuments></LuTemplateDocuments>
      </v-tab-item>
      <v-tab>Document Type</v-tab>
      <v-tab-item>
        <LuDocumentType></LuDocumentType>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
  <script>
import User from '@components/admin/User'
import LuBusinessType from '@components/admin/LuBusinessType'
import LuDocumentType from '@components/admin/LuDocumentType'
import LuHempOwnership from '@components/admin/LuHempOwnership'
import LuLicenseSubType from '@components/admin/LuLicenseSubType'
import LuLicenseType from '@components/admin/LuLicenseType'
import LuTemplateDocuments from '@components/admin/LuTemplateDocuments'
//import LuTemplateDocuments from '@components/admin/LuTemplateDocuments'
export default {
  page: {
    title: 'Admin',
  },
  components: {
    User,
    LuBusinessType,
    LuDocumentType,
    LuHempOwnership,
    LuLicenseSubType,
    LuLicenseType,
    LuTemplateDocuments,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
  <style scoped></style
></>
