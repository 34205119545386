<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="primary headline text-left white--text">Template Documents</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field v-model="searchText" prepend-inner-icon="mdi-magnify" label="Search" clearable>
              </v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" fab @click="addLuTemplateDocuments" depressed small>
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Template Documents</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog persistent v-model="dialog" @keydown.esc="dialog = false" width="850">
            <v-form ref="luTemplateDocumentsForm" @submit.prevent lazy-validation v-model="valid">
              <v-card class="elevation-3">
                <v-card-title class="primary headline text-left white--text">
                  {{modalText}}
                </v-card-title>
                <v-card-text class="pa-4">
                  <v-row>
                    <v-col sm="12">
                      <v-text-field 
                        label="Description" 
                        v-model="selectedLuTemplateDocuments.Description"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field 
                        label="Document I D" 
                        v-model="selectedLuTemplateDocuments.DocumentID"
                        type="number"
                       :rules="[v => !!v || 'required']"
                     ></v-text-field>
                    </v-col>
                  </v-row> -->
                  <!-- //templateTODO: The multiselects will need to have the props updated to the correct values

                  We are not able to determine the nested relations for the template generation
                  you will also need to load the appropriate lookup component-->
                  <v-row v-if="selectedLuTemplateDocuments.ID == 0"no-gutters>
                    <v-col sm="12">
          <div class="dropbox">
            <input class="input-file" type="file" @change="getFiles" />
            <p
              v-if="uploadList.length == 0"
              class="extra-margin"
            >Drag file to upload or click to browse</p>
            <div v-if="uploadList.length > 0" class="extra-margin">
              <p class="uploadList" v-for="(item, index) in items" :key="index">{{ item.name }}</p>
            </div>
          </div>
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuLicenseType
                      label="License Type"
                      v-model="selectedLuTemplateDocuments.LuLicenseType"
                      selfKey="LuTemplateDocumentsID"
                      :selfID="selectedLuTemplateDocuments.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuLicenseType"
                      ></LuLicenseType>
                    </v-col>
                  </v-row> -->
                  <v-card-actions>
                    <v-btn v-if="selectedLuTemplateDocuments.ID > 0" @click="saveLuTemplateDocumentsDetails" :disabled="saving" color="primary">Save</v-btn>
                    <v-btn v-else @click="uploadDocument" :disabled="saving" color="primary">Upload</v-btn>
                    <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="luTemplateDocuments"
            :search="searchText"
            class="pt-4"
            :loading="loading"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="editLuTemplateDocuments(item)">
                    <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit LuTemplateDocuments Information</span>
              </v-tooltip>
            </template>
            <template  v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0 && item.LuLicenseType && item.LuLicenseType.length == 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="deleteConfirm(item)">
                    <v-icon class="clickable" color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Template Document</span>
              </v-tooltip>
            </template>
            <template v-slot:item.download="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="downloadDocument(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-download</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Download Document</span>
                      </v-tooltip>
                    </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary headline">
                Confirm?
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12">Are you sure you want to delete this Template Documents?</v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="deleteDialog = !deleteDialog">Cancel</v-btn>
                  <v-btn @click="callDelete" outlined color="primary">Delete</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import download from 'downloadjs'
  import { get, sync, commit, call } from 'vuex-pathify'
  import LuTemplateDocuments from '@classes/LuTemplateDocuments'
  // import LuLicenseType from '@components/select/single/LuLicenseType'
  //templateTODO: import lookup components you plan to use

  export default {
      page: {
        title: 'Template Documents',
      },
//       components: {
// LuLicenseType
//       },
      data: () => ({
        valid: true,
        selectedLuTemplateDocuments: new LuTemplateDocuments(),
        searchText: '',
        dialog: false,
        deleteDialog: false,
        modalText: '',
              uploadList: [],
      items: [],
      }),
      created() {
          this.loadLuTemplateDocuments()
      },
      computed: {
        ...get('luTemplateDocuments', ['luTemplateDocuments', 'loading']),
        ...sync('luTemplateDocuments', ['saving']),
        headers() {
          return [
          {
              text: '',
              align: 'center',
              value: 'edit',
              visible: true,
              sortable: false,
          },
          {
              text: '',
              align: 'center',
              value: 'download',
              visible: true,
              sortable: false,
          },
          {
            text: 'I D',
            align: 'center',
            sortable: true,
            value: 'ID',
            visible: false,
          },
          {
            text: 'Description',
            align: 'center',
            sortable: true,
            value: 'Description',
            visible: true,
          },
          {
            text: 'Document File Name',
            align: 'center',
            sortable: true,
            value: 'Document.FileName',
            visible: true,
          },
          //{
          //  text: 'Document',
          //  align: 'center',
          //  sortable: true,
          // //templateFIXME: this is just a guess, and may need changed
          //  value: 'Document.Description',
          //  visible: true,
          //},
          {
            text: '',
            align: 'center',
            value: 'delete',
            visible: true,
            sortable: false,
          },
          ]
        },
        filteredHeaders() {
          return this.headers.filter((h) => h.visible)
        },
      },
      methods: {
        handleError: call('errors/handleError'),
        ...call('luTemplateDocuments', ['saveLuTemplateDocuments', 'deleteLuTemplateDocuments', 'loadLuTemplateDocuments', 'clearLookupCacheAndReloadLuTemplateDocuments']),
        async validate() {
          this.$refs.luTemplateDocumentsForm.validate()
        },
        editLuTemplateDocuments(entry) {
          this.selectedLuTemplateDocuments = new LuTemplateDocuments(entry)
          this.modalText = 'Edit Template Documents'
          this.dialog = !this.dialog
        },
        addLuTemplateDocuments() {
          this.modalText = 'Insert Template Documents'
          this.selectedLuTemplateDocuments = new LuTemplateDocuments()
          this.dialog = !this.dialog
        },
        deleteConfirm(item) {
          this.selectedLuTemplateDocuments = item
          this.deleteDialog = true
        },
        callDelete() {
          this.deleteLuTemplateDocuments(this.selectedLuTemplateDocuments).then(
            (res) => {
              this.clearLookupCacheAndReloadLuTemplateDocuments()
              this.deleteDialog = false
            },
            (err) => { }
          )
        },
            getFiles: function(event) {
      this.uploadList = []
      for (let file in event.target.files) {
        this.uploadList.push(event.target.files[file])
      }
      for (let i = 0; i < this.uploadList.length - 2; i++) {
        if (this.uploadList[i].name) this.items.push(this.uploadList[i])
      }
    },
      uploadDocument() {
      this.validate().then(() => {
        if (this.valid) {
          var self = this
          var docType = 2
          this.saving = true
          for (let i = 0; i < this.uploadList.length - 2; i++) {
            let file = this.uploadList[i]
            let fileReader = new FileReader()
            if (fileReader && file) {
              fileReader.readAsDataURL(file)
              fileReader.onload = function() {
                let contents = fileReader.result.split(',')[1]
                let obj = {
                  ID: 0,
                  Description: self.selectedLuTemplateDocuments.Description,
                  DocumentID: 0,
                  Document: {
                    ID: 0,
                    FileName: file.name,
                    MimeType: file.type,
                    DocumentTypeID: docType,
                    DocumentFile: {
                      Image: contents,
                    },
                  },
                }
                axios
                  .post(`/LuTemplateDocuments/`, obj)
                  .then(function(response) {
                    self.dialog = false
                    self.items = []
                    self.upload = []
                    self.clearLookupCacheAndReloadLuTemplateDocuments()
                  })
                  .catch(function(error) {
                    console.log(error)
                    self.handleError(error)
                    // this.error = error
                    // this.displayError = true
                  })
              }
            }
          }
          this.saving = false
          this.addDocsDialog = false
        }
      })
    },
        downloadDocument(doc) {
      let url
      url = `LuTemplateDocuments/Download/${doc.ID}`
      let headers = { responseType: 'blob' }
      this.$axios.get(url, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.log(error)
        }
      )
    },
        saveLuTemplateDocumentsDetails() {
          this.validate().then(() => {
            if (this.valid) {
              //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
              this.saveLuTemplateDocuments(this.selectedLuTemplateDocuments.removeRelated()).then(
                (res) => {
                  this.clearLookupCacheAndReloadLuTemplateDocuments()
                  this.selectedLuTemplateDocuments = new LuTemplateDocuments()
                  this.$refs.luTemplateDocumentsForm.resetValidation()
                },
                (err) => { }
              )
              this.dialog = false
            }
          })
        },
        cancelEntry() {
          this.dialog = false 
          this.selectedLuTemplateDocuments = new LuTemplateDocuments()
          this.$refs.luTemplateDocumentsForm.resetValidation()
        }
      },
  } 
  
</script>
<style scoped>
.dropbox {
  outline: 2px dashed rgb(190, 190, 190); /* the dash box */
  outline-offset: -10px;
  background: #fafafa;
  color: dimgray;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  /* width: 20%; */
  height: 100px;
  /* margin-left: auto;
  margin-right: auto;  */
  margin-top: 10px;
}

.input-file {
  outline: 2px solid black;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
</style>