<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="primary headline text-left white--text">Document Type</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" fab @click="addLuDocumentType" depressed small>
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Document Type</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog persistent v-model="dialog" @keydown.esc="dialog = false" width="600">
            <v-form ref="luDocumentTypeForm" @submit.prevent lazy-validation v-model="valid">
              <v-card class="elevation-3">
                <v-card-title class="primary headline text-left white--text">{{modalText}}</v-card-title>
                <v-card-text class="pa-4">
                  <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Description"
                        v-model="selectedLuDocumentType.Description"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Insert By I D"
                        v-model="selectedLuDocumentType.InsertByID"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Insert Date mm/dd/yyyy"
                        v-model="selectedLuDocumentType.InsertDate"
                        :rules="[v => !!v || 'required']"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Update By I D"
                        v-model="selectedLuDocumentType.UpdateByID"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Update Date mm/dd/yyyy"
                        v-model="selectedLuDocumentType.UpdateDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <v-row>
                    <v-col sm="6">
                      <v-checkbox label="Is Active" v-model="selectedLuDocumentType.IsActive"></v-checkbox>
                    </v-col>
                    <v-col sm="6">
                      <v-checkbox label="Internal Only" v-model="selectedLuDocumentType.InternalOnly"></v-checkbox>
                    </v-col>
                  </v-row>

                  <!-- //templateTODO: The multiselects will need to have the props updated to the correct values

                  We are not able to determine the nested relations for the template generation
                  you will also need to load the appropriate lookup component-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedLuDocumentType.Document"
                      selfKey="LuDocumentTypeID"
                      :selfID="selectedLuDocumentType.ID"
                      relatedItemKey="RelationID"
                      joinItemName="Document"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedLuDocumentType.LicenseDocumentTypes"
                      selfKey="LuDocumentTypeID"
                      :selfID="selectedLuDocumentType.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LicenseDocumentTypes"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <v-card-actions>
                    <v-btn
                      @click="saveLuDocumentTypeDetails"
                      :disabled="saving"
                      color="primary"
                    >Save</v-btn>
                    <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="luDocumentType"
            :search="searchText"
            class="pt-4"
            :loading="loading"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="editLuDocumentType(item)">
                    <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Document Type Information</span>
              </v-tooltip>
            </template>
            <template v-slot:item.IsActive="{ item }">{{ item.IsActive? 'Active': 'Inactive'}}</template>
            <template v-slot:item.InternalOnly="{ item }">{{ item.InternalOnly? 'Internal': 'Everyone'}}</template>
            <template v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="deleteConfirm(item)">
                    <v-icon class="clickable" color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Document Type</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary headline">Confirm?</v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12">Are you sure you want to delete this luDocumentType?</v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="deleteDialog = !deleteDialog">Cancel</v-btn>
                  <v-btn @click="callDelete" outlined color="primary">Delete</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuDocumentType from '@classes/LuDocumentType'
//templateTODO: import lookup components you plan to use
export default {
  page: {
    title: 'LuDocumentType',
  },
  data: () => ({
    valid: true,
    selectedLuDocumentType: new LuDocumentType(),
    searchText: '',
    dialog: false,
    deleteDialog: false,
    modalText: '',
  }),
  created() {
    this.loadLuDocumentType()
  },
  computed: {
    ...get('luDocumentType', ['luDocumentType', 'saving', 'loading']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'I D',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Insert By I D',
          align: 'center',
          sortable: true,
          value: 'InsertByID',
          visible: false,
        },
        {
          text: 'Insert Date',
          align: 'center',
          sortable: true,
          value: 'InsertDate',
          visible: false,
        },
        {
          text: 'Update By I D',
          align: 'center',
          sortable: true,
          value: 'UpdateByID',
          visible: false,
        },
        {
          text: 'Update Date',
          align: 'center',
          sortable: true,
          value: 'UpdateDate',
          visible: false,
        },
        {
          text: 'Active?',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: true,
        },
        {
          text: 'Available To',
          align: 'center',
          sortable: true,
          value: 'InternalOnly',
          visible: true,
        },
        //{
        //  text: 'Insert By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'InsertBy.Description',
        //  visible: true,
        //},
        //{
        //  text: 'Update By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'UpdateBy.Description',
        //  visible: true,
        //},
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luDocumentType', [
      'saveLuDocumentType',
      'deleteLuDocumentType',
      'loadLuDocumentType',
      'clearLookupCacheAndReloadLuDocumentType',
    ]),
    async validate() {
      this.$refs.luDocumentTypeForm.validate()
    },
    editLuDocumentType(entry) {
      this.selectedLuDocumentType = new LuDocumentType(entry)
      this.modalText = 'Edit Document Type'
      this.dialog = !this.dialog
    },
    addLuDocumentType() {
      this.modalText = 'Add Document Type'
      this.selectedLuDocumentType = new LuDocumentType()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedLuDocumentType = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteLuDocumentType(this.selectedLuDocumentType).then(
        (res) => {
          this.clearLookupCacheAndReloadLuDocumentType()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveLuDocumentTypeDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuDocumentType(
            this.selectedLuDocumentType.removeRelated()
          ).then(
            (res) => {
              this.clearLookupCacheAndReloadLuDocumentType()
              this.selectedLuDocumentType = new LuDocumentType()
              this.$refs.luDocumentTypeForm.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuDocumentType = new LuDocumentType()
      this.$refs.luDocumentTypeForm.resetValidation()
    },
  },
}
</script>
<style>
</style>