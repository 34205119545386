<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="primary headline text-left white--text">Business Type</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" fab @click="addLuBusinessType" depressed small>
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Business Type</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog persistent v-model="dialog" @keydown.esc="dialog = false" width="600">
            <v-form ref="luBusinessTypeForm" @submit.prevent lazy-validation v-model="valid">
              <v-card class="elevation-3">
                <v-card-title class="primary headline text-left white--text">{{modalText}}</v-card-title>
                <v-card-text class="pa-4">
                  <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Description"
                        v-model="selectedLuBusinessType.Description"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Insert By I D"
                        v-model="selectedLuBusinessType.InsertByID"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Insert Date mm/dd/yyyy"
                        v-model="selectedLuBusinessType.InsertDate"
                        :rules="[v => !!v || 'required']"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Update By I D"
                        v-model="selectedLuBusinessType.UpdateByID"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <BaseDatePickerWithText
                        label="Update Date mm/dd/yyyy"
                        v-model="selectedLuBusinessType.UpdateDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>-->
                  <v-row>
                    <v-col sm="12">
                      <v-checkbox label="Is Active" v-model="selectedLuBusinessType.IsActive"></v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- //templateTODO: The multiselects will need to have the props updated to the correct values

                  We are not able to determine the nested relations for the template generation
                  you will also need to load the appropriate lookup component-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <User
                      ></User>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedLuBusinessType.HempLicense"
                      selfKey="LuBusinessTypeID"
                      :selfID="selectedLuBusinessType.ID"
                      relatedItemKey="RelationID"
                      joinItemName="HempLicense"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <v-card-actions>
                    <v-btn
                      @click="saveLuBusinessTypeDetails"
                      :disabled="saving"
                      color="primary"
                    >Save</v-btn>
                    <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="luBusinessType"
            :search="searchText"
            class="pt-4"
            :loading="loading"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="editLuBusinessType(item)">
                    <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Business Type Information</span>
              </v-tooltip>
            </template>
            <template v-slot:item.IsActive="{ item }">{{ item.IsActive? 'Active': 'Inactive'}}</template>
            <template v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="deleteConfirm(item)">
                    <v-icon class="clickable" color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete LuBusinessType</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary headline">Confirm?</v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12">Are you sure you want to delete this luBusinessType?</v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="deleteDialog = !deleteDialog">Cancel</v-btn>
                  <v-btn @click="callDelete" outlined color="primary">Delete</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuBusinessType from '@classes/LuBusinessType'
//templateTODO: import lookup components you plan to use
export default {
  page: {
    title: 'LuBusinessType',
  },
  data: () => ({
    valid: true,
    selectedLuBusinessType: new LuBusinessType(),
    searchText: '',
    dialog: false,
    deleteDialog: false,
    modalText: '',
  }),
  created() {
    this.loadLuBusinessType()
  },
  computed: {
    ...get('luBusinessType', ['luBusinessType', 'saving', 'loading']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'I D',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Insert By I D',
          align: 'center',
          sortable: true,
          value: 'InsertByID',
          visible: false,
        },
        {
          text: 'Insert Date',
          align: 'center',
          sortable: true,
          value: 'InsertDate',
          visible: false,
        },
        {
          text: 'Update By I D',
          align: 'center',
          sortable: true,
          value: 'UpdateByID',
          visible: false,
        },
        {
          text: 'Update Date',
          align: 'center',
          sortable: true,
          value: 'UpdateDate',
          visible: false,
        },
        {
          text: 'Active?',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: true,
        },
        //{
        //  text: 'Insert By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'InsertBy.Description',
        //  visible: true,
        //},
        //{
        //  text: 'Update By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'UpdateBy.Description',
        //  visible: true,
        //},
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luBusinessType', [
      'saveLuBusinessType',
      'deleteLuBusinessType',
      'loadLuBusinessType',
      'clearLookupCacheAndReloadLuBusinessType',
    ]),
    async validate() {
      this.$refs.luBusinessTypeForm.validate()
    },
    editLuBusinessType(entry) {
      this.selectedLuBusinessType = new LuBusinessType(entry)
      this.modalText = 'Edit Business Type'
      this.dialog = !this.dialog
    },
    addLuBusinessType() {
      this.modalText = 'Add Business Type'
      this.selectedLuBusinessType = new LuBusinessType()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedLuBusinessType = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteLuBusinessType(this.selectedLuBusinessType).then(
        (res) => {
          this.clearLookupCacheAndReloadLuBusinessType()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveLuBusinessTypeDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuBusinessType(
            this.selectedLuBusinessType.removeRelated()
          ).then(
            (res) => {
              this.clearLookupCacheAndReloadLuBusinessType()
              this.selectedLuBusinessType = new LuBusinessType()
              this.$refs.luBusinessTypeForm.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuBusinessType = new LuBusinessType()
      this.$refs.luBusinessTypeForm.resetValidation()
    },
  },
}
</script>
<style>
</style>