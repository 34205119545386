<template>
<!-- Example usage:
  <selectMultiLuRole
    v-model="selectedUser.UserRole" (the collection we are updating)
    selfKey="UserID" (how the selectedUser is referenced in the join item)
    :selfID="selectedUser.ID" (to create new entries we need the selected users ID)
    relatedItemKey="RoleID" (this is how the lookup is referenced in the join)
    joinItemName="UserRole" (this is the name of the join (for the js class))
  ></selectMultiLuRole>
-->
  <v-select
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="ID"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
    chips
    deletable-chips
    multiple
    dense
  ></v-select>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  import Document from '@classes/Document'
  import LicenseDocumentTypes from '@classes/LicenseDocumentTypes'
  export default {
    name: 'select-multi-join-LuDocumentType',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        default: 'Lu Document Type',
        },
      value: Array,
      clearable: {
        type: Boolean,
        default: false,
      },
      rules: Array,
      joinItemName: {
        type: String,
        required: true,
        //default: 'Document',
        //default: 'LicenseDocumentTypes',
      },
      relatedItemKey: {
        type: String,
        required: true,
        //default: 'LuDocumentTypeID',
      },
      selfKey: {
        type: String,
        required: true,
      },
      selfID: {
        type: Number,
        required: true,
      }
    },
    data(){
      return {
        itemText: 'Description',
      }
    },
    computed: {
     ...get('luDocumentType',['luDocumentType', 'loading']),
      availableItems() {
          //if you have a property (like an IsActive boolean) you can change the true to filer i.e. i.IsActive
          var list = this.luDocumentType.filter((i) => true )
          list = list.filter((i) => i.IsActive )
          // if existing record make sure the previous selection is available even if no longer active
          if (this.value > 0) {
            var selectedItem = this.luDocumentType.find((i) => i.ID == this.value)
            if (!list.find((i) => i.ID == this.value) && selectedItem) {
              list.push(selectedItem)
            }
          }
          // sort by column
          list.sort((a, b) => {
            //set the property to sort on
            let textField = this.itemText
            let sort = a[textField] > b[textField] ? 1 : -1
            return sort
          })
          return list
      },
      selected: {
        get() {
          if (this.value && this.value.length > 0) {
            return [
              ...new Set(
                this.value.map((item) => item[this.relatedItemKey])
              ),
            ]
          } else {
            return []
          }
        },
        set(newValue) {
          if (this.value) {
            this.value.forEach(
            (relation, index, object) => {
              if (!newValue.includes(relation[this.relatedItemKey])) {
                //need to remove item
                object.splice(index, 1)
              }
            }
            )
            newValue.forEach((itemID) => {
              if (
                !this.value.find(
                  (i) => i[this.relatedItemKey] == itemID
                )
              ) {
                // need to add item
                let item
                if (this.joinItemName == 'Document') {
                  item = new Document()
                }
                else
                if (this.joinItemName == 'LicenseDocumentTypes') {
                  item = new LicenseDocumentTypes()
                }
                else
                {item = {}}
                item[this.relatedItemKey]= itemID
                item[this.selfKey]= this.selfID
                this.value.push(item)
              }
            })
          }
        }
      }
    },
    created(){
      this.loadLuDocumentType()
    },
    methods: {
      loadLuDocumentType: call('luDocumentType/loadLuDocumentType'),
    },
  }
</script>
<style>

</style>